<template>
	<vue-final-modal
		v-if="isShow"
        v-model="isShow"
        @click-outside="closeHandler">
		<div class="slot-create-page" v-if="isShow" v-bind:class="{
			'show__modal' : isOpenAnimation,
			'hide__modal' : isCloseAnimation,
		}">
			<SlotSettings v-if="!calendarIsDefault && isCalendarPage && userHaveAccess" 
				:submitHandler="submitHandler"
				:closeHandler="closeHandler"/>
			<SlotShortSettings v-else 
				:submitHandler="submitHandler"
				:closeHandler="closeHandler"/>
		</div>
	</vue-final-modal>
</template>

<script>
import router from '../router';
import YYYYMMDD, { convertTZ } from '../scripts/date';
import Slot from '../scripts/slot';
import CMS from '../service/cms/service';
import { isEqualDate } from '../scripts/date';
import { bus } from '../components/calendar/EventBus';
import { globalBus } from '../GlobalEventBus';
import { ADMIN_MEMBERSHIP_LEVEL, MANAGER_MEMBERSHIP_LEVEL, OWNER_MEMBERSHIP_LEVEL } from '../scripts/membershipLevels';

export default {
	name: 'SlotCreate',

	components: {
		SlotSettings: () => import("../components/calendar/table/SlotSettings.vue"),
		SlotShortSettings: () => import("../components/calendar/table/SlotShortSettings.vue"),
	},

	computed: {
		calendarUid(){
			if (this.$route.name != "calendar" ||
				(!this.userHaveAccess && !this.calendarIsDefault))
				return this.$store.getters.getDefaultCalendar
			return this.$route.params.calendarUid
		},
		calendarTimezone(){
			if (!this.userHaveAccess && !this.calendarIsDefault){
				let userTimezoneId = this.$store.getters.userTimezoneId
				return this.$store.getters.getTimezoneByID(userTimezoneId)
			}
			
			return this.$store.getters.getTimezone
		},
		calendarIsDefault(){
			if (this.$route.name != 'calendar')
				return false
			let defaultUserCalendar = this.$store.getters.getDefaultCalendar
			let currentCalendar = this.$route.params.calendarUid
			return defaultUserCalendar == currentCalendar
		},
		browserTimezone(){
            return this.$store.getters.browserTimezone
		},
		isCalendarPage(){
			return this.$route.name == "calendar"
		},
		userHaveAccess(){
			let level = this.$store.getters.getMembershipLevel
			if (level == -1)
				return false
			
			return level == MANAGER_MEMBERSHIP_LEVEL ||
				level == OWNER_MEMBERSHIP_LEVEL ||
				level == ADMIN_MEMBERSHIP_LEVEL
		}
	},

	created() {
		globalBus.$on('slot-create', 'show', (value) => {
			if (this.isOpenAnimation || this.isCloseAnimation)
				return
			globalBus.$emit("static-menu", "update-visible", {
				visible: false
			})
			setTimeout(() => {
				this.isShow = true
				this.isOpenAnimation = true
				setTimeout(() => this.isOpenAnimation = false, 600)
			}, 300)

		})
	},

	data() {
		return {
			isOpenAnimation: false,
			isCloseAnimation: false,
			isShow: false,
			isWaitResponse: false,
		};
	},

	methods: {
		closeHandler(){
			if (this.isOpenAnimation || this.isCloseAnimation)
				return
			this.isCloseAnimation = true
			setTimeout(() => {
				globalBus.$emit("static-menu", "update-visible", {
					visible: true,
				})
				
			}, 600)
			setTimeout(() => {
				this.isCloseAnimation = false
				this.isShow = false
			}, 650)
		},
		submitHandler(form){
			if (!form || this.isWaitResponse)
				return

            let browserTimezone = this.$store.getters.browserTimezone

            // 1. Basic validation
            let isValid = form.validate()
            if (!isValid) return 

            // 2. Date validation with difference between timezones
            isValid = form.validateConvertedDate(browserTimezone)
            if (!isValid) return

            // 3. Get form data to submit
            let slotData = form.toSubmitData()
            let calendarUid = this.calendarUid
			this.isWaitResponse = true
			this.$store.dispatch('create-slot', {calendarUid, slotData})
				.then(() => {
					if (form.isCreateTemplate){
						this.$store.dispatch('setup-slot-templates')
					}
					this.closeHandler()
					this.isWaitResponse = false
				})
            // CMS.slots.update(calendarUid, this.eventSlot.index, submitData)
            // .then( () => {
            //     let formDate = form.calendarDate ?? form.date
            //     let date = new Date(formDate.getTime())
            //     let slotStart = this.eventSlot.period.start.date
            //     let dateIsChanged = !isEqualDate(date, slotStart)

            //     // If date is changed -> change card column
            //     if (dateIsChanged){
            //         bus.$emit('table', 'update-slot-date', {
            //             date: date,
            //             index: this.eventSlot.index,
            //             isAfterPut: true,
            //         })
            //     }

            //     // Update card view
            //     bus.$emit(`new-card-${this.eventSlot.index}`, `update-card-info`, {
            //         date: date,
            //         title: form.title,
            //         maxAttenders: form.maxAttenders,
            //         mainColor: form.cellBackgroundColor,
            //         textColor: form.cellTextColor,
            //         isAfterPut: true,
            //     })
                
            //     // Update slot data
            //     submitData.startAt = new Date(date.getTime())
            //     submitData.dur = form.duration
            //     this.eventSlot.updateSettings(submitData)

            //     if (form.isCreateTemplate){
            //         this.$store.dispatch('setup-slot-templates')
            //     }
            //     this.goToCalendar(this.eventSlot.period.start.date)
            // })
		}
	},
};
</script>

<style scoped>
.slot-create-page{
	position: fixed;
    margin: 32px 0 0 0;
	width: 100%;
	left: 0;
	background: white;
	z-index: 4;
	top: 0;
	bottom: 0;
}

.show__modal{
	animation: .6s modal-mounted cubic-bezier(0.22, 0.61, 0.36, 1);
}

.hide__modal{
	animation: .6s modal-mounted cubic-bezier(0.22, 0.61, 0.36, 1) reverse;
	top: 80vh;
}

@keyframes modal-mounted {
    from {
        top: 80vh;
    }
    to{
        top: 0;
    }   
}
</style>